import React, { useEffect, useRef } from "react"

import ImageAndTitle from "./imageAndTitle/imageAndTitle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as css from "./layoutLanding.module.css"

function LayoutLanding({ children }) {
  const imagesRef = useRef([])

  useEffect(() => {
    const html = document.documentElement
    html.classList.add(css.overflowHidden)
    imagesRef.current.children[0].style.opacity = "1"
    let counter = 0
    const imageInterval = setInterval(() => {
      imagesRef.current.children[counter % 4].style.opacity = "0"
      imagesRef.current.children[(counter + 1) % 4].style.opacity = "1"
      counter++
    }, 5000)
    return () => {
      html.classList.remove(css.overflowHidden)
      clearInterval(imageInterval)
    }
  }, [])

  return (
    <div className="landingPageContainer">
      {children}
      <header className={css.header}>
        <ImageAndTitle longVersion="true" />
      </header>
      <main className={css.main}>
        <div className={css.links}>
          <div>
            <h2>Przedszkole</h2>
            <Link to="/przedszkole">Przejdź do strony przedszkola</Link>
          </div>
          <div>
            <h2>Szkoła Podstawowa</h2>
            <Link to="/szkola-podstawowa">
              Przejdź do strony szkoły podstawowej
            </Link>
          </div>
        </div>
        <div ref={imagesRef} className={css.photos}>
          <div className={css.photo}>
            <StaticImage
              src="../images/landing_page/landing_page_1.jpg"
              alt=""
              width={2268}
              height={2268}
            />
          </div>
          <div className={css.photo}>
            <StaticImage
              src="../images/landing_page/landing_page_2.jpg"
              alt=""
              width={2268}
              height={2268}
            />
          </div>
          <div className={css.photo}>
            <StaticImage
              src="../images/landing_page/landing_page_3.jpg"
              alt=""
              width={2268}
              height={2268}
            />
          </div>

          <div className={css.photo}>
            <StaticImage
              src="../images/landing_page/landing_page_4.jpg"
              alt=""
              width={2268}
              height={2268}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default LayoutLanding
