import React from "react"
import LayoutLanding from "../components/layoutLanding"
import SEO from "../components/seo"

const IndexPage = () => (
  <LayoutLanding>
    <SEO title="Strona główna - ZSP Nidek" />
  </LayoutLanding>
)

export default IndexPage
